.input-field{
	border-radius: var(--inputFieldBorderRadius) !important;
	padding: 15px 15px 15px 15px !important;
	font-weight: 400 !important;
	border: 1px solid rgb(235, 235, 235) !important;
	margin-bottom: 5px!important;
	outline: none !important;
	background: rgb(246, 246, 246) !important;
}

.input-field-select{
	border-radius: var(--inputFieldBorderRadius) !important;
	padding: 15px 15px 15px 15px !important;
	font-weight: 400 !important;
	border: 1px solid rgb(235, 235, 235) !important;
	margin-bottom: 5px!important;
	outline: none !important;
	background-color: rgb(246, 246, 246) !important;
}

.input-field-select:focus {
	border: 1px solid var(--darkGrey) !important;
	box-shadow:inset 0px 0px 0px 1px var(--darkGrey);

}


.text-muted{
	margin-left: 20px !important;
}
form .form-select:focus{
	box-shadow:inset 0px 0px 0px 1px var(--darkGrey);
	border: 0px !important;
}

form .form-control:focus{
	box-shadow:inset 0px 0px 0px 1px var(--darkGrey);
	border: -1px !important;
}

.input-field-small{
	border-radius: var(--inputFieldBorderRadius) !important;
	background-color: rgb(245, 245, 245) !important;
	padding: 10px 10px 10px 25px !important;
	font-weight: 500 !important;
	border-color: rgb(197, 197, 197) !important;
}

.input-label{
	font-weight: 700;
	margin-left: 15px !important;
}

.input-field::placeholder{
	font-weight: 400;
	font-size: 0.9rem !important;
}

.input-field-blog::placeholder{
	font-weight: 400;
	font-size: 0.9rem !important;
}

.label-icons{
	margin-right: 8px !important;
	width: 25px !important;
}


/* === CK EDITOR === */
.ck-editor__editable_inline {
    max-height: calc(100vh - 160px);
	min-height: 300px;
    border-radius: 0px 0px 25px 25px  !important;
    background: rgb(246, 246, 246) !important;
	border: 1px solid rgb(235, 235, 235) !important;
}

.ck-editor__editable_inline:focus{
    box-shadow: none !important;
	border: 1px solid var(--darkGrey) !important;
}

.ck.ck-toolbar {
    border-radius: 25px 25px 0px 0px  !important;
	border: 1px solid rgb(235, 235, 235) !important;
}
