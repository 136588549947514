@import './buttons.css';
@import './font.css';
@import './forms.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


* {
	font-family: 'Poppins', Helvetica, Arial, sans-serif;
	box-sizing: border-box;
	scroll-behavior: smooth;
}
/* ==== is used to keep footer at bottom of page regardless of page height ==== */
.root-container {
	min-height: 100vh !important;
}

body {
	margin: 0;
}

#root {
	background: none;
	margin: 0 !important;
}

html {
	scroll-padding-top: 250px;
}

link {
	text-decoration: none;
}

.menu-placeholder {
	height: 78px;
	background: var(--darkGrey);
}

a {
	text-decoration: none;
}

.btn:focus {
	box-shadow: none !important;
}

:root {
	/*  === Common colors === */
	--darkBackground: #333333;
	--backgroundGray: #eeeeee;
	--black: black;
	--white: #ffffff;
	--darkGrey: #333333;
	--nordaxonGold: #e1c500;
	--danger: #a84949;
	--dangerLight: hsl(0, 69%, 57%);

	--btnBorderGold: inset 0px 0px 0px 3px var(--nordaxonGold) !important;
	--btnBorderDanger: inset 0px 0px 0px 3px var(--danger) !important;
	--btnBorderDark: inset 0px 0px 0px 2px var(--darkGrey) !important;
	--btnBorderCategory: inset 0px 0px 0px 1px rgb(205, 205, 205) !important;

	/* === Common padding === */
	--btnPadding: 14px 55px 14px 45px !important;
	--btnSmallPadding: 12px 40px 12px 40px !important;
	--btnXsSmallPadding: 4px 5px 6px 5px !important;
	--btnSmallPaddingCat: 9px 20px 8px 20px !important;

	/* === Common transitions === */
	--btnTransition: 0.5s !important;

	/* === Common radius === */
	--btnRadius: 45px !important;
	--btnRoundRadius: 50% !important;
	--inputFieldBorderRadius: 25px;

	/* === Common shadow === */
	--smallShadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

	--nav-height: 80px;
}

@keyframes slide-in-bottom {
	from {
		transform: translateY(30%);
	}
	to {
		transform: translateY(0%);
	}
}
@keyframes slide-out-bottom {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(100%);
	}
}
