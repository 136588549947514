.fallback-loader-div {
	background: hsla(0, 0%, 100%, 0.763);
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 9999999;
	height: 100vh;
	transition: 400ms ease-in-out;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 70px;
	height: 70px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 45px;
	height: 45px;
	margin: 8px;
	border: 5px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--nordaxonGold) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
