::selection {
	background: var(--nordaxonGold);
}

.header-title {
	font-size: 2.2rem !important;
}

.header-text {
	font-size: 1.1rem !important;
	font-weight: 400 !important;
	max-width: 70ch !important;
}

.header-top-text {
	font-size: 1.1rem !important;
	color: var(--nordaxonGold) !important;
}

.header-text-wrapper {
	padding: 40px !important;
	color: white !important;
}

.page-headline {
	font-size: 2rem !important;
}

.page-text {
	font-size: 1rem;
	line-height: 1.6rem !important;
}

.header-wrapper {
	padding: 25px;
}

h1,
h2,
h3,
h4 {
	font-weight: 700 !important;
}

.big-headline {
	font-size: 3rem !important;
	font-weight: 700 !important;
}

.main-headline {
	font-size: 2.3rem !important;
	font-weight: 700 !important;
}

.large-main-headline {
	font-size: 5rem !important;
	line-height: 1 !important;
}

.headline-1 {
	font-size: 2rem !important;
	font-weight: 700 !important;
	text-decoration: underline;
	text-decoration-color: var(--nordaxonGold);
	text-underline-offset: 5px;
	text-decoration-thickness: 3px;
	line-height: 1.8;
	font-family: 'Poppins' !important;
}

.headline-2 {
	font-size: 1.8rem !important;
	font-weight: 700 !important;
}

.headline-3 {
	font-size: 1.2rem !important;
	font-weight: 700 !important;
}

.headline-4 {
	font-size: 1.1rem !important;
	font-weight: 700 !important;
}

.headline-5 {
	font-size: 1.3rem !important;
	font-weight: 700 !important;
}

.headline-6 {
	font-size: 1.6rem !important;
}

.headline-text {
	font-size: 1.5rem !important;
	font-weight: 500 !important;
}

.card-text {
	font-size: 1rem !important;
	line-height: 1.8 !important;
	font-weight: 400;
}

.member-text {
	font-size: 1.2rem !important;
}

.member-text-2 {
	font-size: 0.9rem !important;
}

.menu-active {
	color: var(--nordaxonGold) !important;
	box-shadow: inset 0 -3px 0 var(--nordaxonGold) !important;
}

@media (max-width: 1000px) {
	.header-title {
		font-size: 1.8rem !important;
	}

	.header-text {
		font-size: 1rem !important;
		font-weight: 400 !important;
	}

	.header-top-text {
		font-size: 1rem !important;
		color: var(--nordaxonGold) !important;
	}

	.header-text-wrapper {
		padding: 30px !important;
		color: white !important;
	}

	.page-headline {
		font-size: 1.8rem !important;
	}

	.page-text {
		font-size: 0.9rem;
	}

	.header-wrapper {
		padding: 25px;
	}

	.main-headline {
		font-size: 1.8rem !important;
	}

	.big-headline {
		font-size: 1.5rem !important;
	}

	.headline-1 {
		font-size: 1.6rem !important;
	}

	.headline-text {
		font-size: 1rem !important;
	}

	.services-card {
		margin: 15px !important;
	}

	.headline-2 {
		font-size: 1.2rem !important;
	}

	.card-text {
		font-size: 1rem !important;
		line-height: 1.5 !important;
	}

	.large-main-headline {
		font-size: 2.5rem !important;
	}
}
