button:focus,
button:active {
	outline: none !important;
}

/* === Common button layout === */
.button-layout {
	padding: var(--btnPadding) !important;
	transition: var(--btnTransition) !important;
	font-weight: 600 !important;
	font-size: 1rem !important;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
}

.small-button-layout {
	padding: var(--btnSmallPadding) !important;
	transition: var(--btnTransition) !important;
	font-weight: 600 !important;
	font-size: 0.9rem !important;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
}

.xs-small-button-layout {
	padding: var(--btnXsSmallPadding) !important;
	font-weight: 600 !important;
	font-size: 0.9rem !important;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
	margin: 0px !important;
}
.xxs-small-button-layout {
	padding: 5px 25px 5px 25px !important;
	font-weight: 600 !important;
	font-size: 0.9rem !important;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
	margin: 0px !important;
	letter-spacing: 0.1px !important;
}
.no-padding-xxs-small-button {
	font-weight: 600 !important;
	font-size: 0.9rem !important;
	padding: 0px;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
	margin: 0px !important;
	letter-spacing: 0.1px !important;
}

.category-small-button-layout {
	padding: var(--btnSmallPaddingCat) !important;
	transition: var(--btnTransition) !important;
	letter-spacing: 0.4px;
	font-weight: 600 !important;
	font-size: 0.9rem !important;
	border-radius: var(--btnRadius) !important;
	border: 0px !important;
	margin: 5px !important;
}

.round-button-layout {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px !important;
	width: 50px !important;
	transition: var(--btnTransition) !important;
	letter-spacing: 1px !important;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	font-size: 1rem !important;
	border-radius: var(--btnRoundRadius) !important;
	border: 0px !important;
	margin: 5px !important;
}

.button-rotate {
	transform: rotate(0.5turn) !important;
}
.button-rotate-2 {
	transform: rotate(0.13turn) !important;
}

.small-round-button-layout {
	justify-content: center;
	align-items: center;
	height: 35px !important;
	width: 35px !important;
	transition: var(--btnTransition) !important;
	letter-spacing: 1px !important;
	font-weight: 700 !important;
	text-transform: uppercase !important;
	font-size: 1rem !important;
	border-radius: var(--btnRoundRadius) !important;
	border: 0px !important;
}

.float-button-layout {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px !important;
	width: 70px !important;
	transition: var(--btnTransition) !important;
	letter-spacing: 1px !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	font-size: 2.5rem !important;
	border-radius: var(--btnRoundRadius) !important;
	border: 0px !important;
	margin: 5px !important;
}

/* === Filled buttons === */
.main-button {
	color: var(--darkGrey) !important;
	background: var(--nordaxonGold) !important;
	border: 0px !important;
	transition: 400ms ease-in-out !important;
	text-decoration: none !important;
}

.main-button:hover {
	background: rgb(203, 177, 30) !important;
}

.main-button > span {
	position: relative;
	right: 0;
	transition: right ease 0.5s;
}
.main-button:hover > span {
	right: -5px;
}

.black-button {
	color: var(--nordaxonGold) !important;
	background: black !important;
	transition: 400ms ease-in-out !important;
}

.black-button:hover {
	background: var(--darkGrey) !important;
}

.dark-button {
	color: white !important;
	background: var(--darkGrey) !important;
	transition: 400ms ease-in-out !important;
	text-decoration: none !important;
}

.dark-button > span {
	position: relative;
	right: 0;
	transition: right ease 0.5s;
}
.dark-button:hover > span {
	right: -5px;
}

.linkedin-button {
	color: white !important;
	background: var(--darkGrey);
	transition: 400ms ease-in-out !important;
	text-decoration: none !important;
}

.linkedin-button:hover {
	background: #0c63bc;
}

.dark-button:hover {
	background: black !important;
}

.xs-dark-button {
	color: black !important;
	background: none !important;
}

.xs-dark-button:hover {
	text-decoration: none !important;
}

.xs-white-button {
	color: #fff !important;
	background: none !important;
}

.xs-white-button:hover {
	text-decoration: none !important;
}

.category-button {
	color: var(--darkGrey) !important;
	background: none !important;
	box-shadow: var(--btnBorderCategory) !important;
	transition: 400ms ease-in-out !important;
}
.category-button:hover {
	background: var(--darkGrey) !important;
	color: white !important;
}

.gold-button {
	color: var(--darkGrey) !important;
	background: var(--nordaxonGold) !important;
	border: 0px !important;
	transition: 400ms ease-in-out !important;
	text-decoration: none !important;
}

.tertiary-button {
	background: none;
	color: var(--darkGrey);
	transition: 400ms ease-in-out !important;
	text-decoration: underline !important;
	text-underline-offset: 4px;
}

.tertiary-button:hover {
	color: var(--nordaxonGold);
}

.gold-button:hover {
	color: var(--nordaxonGold) !important;
	background: none !important;
	box-shadow: var(--btnBorderGold) !important;
}

.add-trans-padding:hover {
	padding-left: 50px !important;
	transition: 400ms ease-in-out !important;
}

.danger-button {
	color: var(--white) !important;
	background: var(--danger) !important;
}

.danger-button:hover {
	color: var(--white) !important;
	background: var(--dangerLight) !important;
}

/* === Outlined buttons === */
.gold-button-outline {
	color: var(--nordaxonGold) !important;
	background: none !important;
	box-shadow: var(--btnBorderGold) !important;
}

.gold-button-outline:hover {
	color: var(--darkGrey) !important;
	background: var(--nordaxonGold) !important;
	box-shadow: var(--btnBorderGold) !important;
}

.gold-button-outline > span {
	position: relative;
	right: 0;
	transition: right ease 0.5s;
}
.gold-button-outline:hover > span {
	right: -5px;
}

.dark-button-outline {
	color: var(--darkGrey) !important;
	background: none !important;
	box-shadow: var(--btnBorderDark) !important;
}

.dark-button-outline:hover {
	color: white !important;
	background: var(--darkGrey) !important;
	box-shadow: var(--btnBorderDark) !important;
}
